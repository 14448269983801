import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Checkboxes allow users to select and deselect items in bulk. They provide the ability for users to select one or several items, deselect, or initiate an action.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/check1.svg",
      "alt": "checkbox anatomy guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Container :`}</strong>{` Selection control (input) that can be selected or unselected, and pushed into different states.`}</li>
      <li parentName="ol"><strong parentName="li">{`Icon :`}</strong>{` Icon that appears within the Checkbox input.`}</li>
      <li parentName="ol"><strong parentName="li">{`Label :`}</strong>{` The Label is the text attributed to the Checkbox that provides context.`}</li>
    </ol>
    <br />
    <br />
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/check2.svg",
      "alt": "checkbox guideline"
    }}></img>
    <p>{`An icon can be added to a checkbox container to show different checkbox states including selected and partial selection.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : Display Vertical" caption="Checkboxes should be displayed vertically, stacked for consistent alignment and positioning across different breakpoints for clear direction" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/check3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Display Horizontal" caption="Avoid using Checkboxes in a horizontal orientation to avoid issues with alignment and legibility when there are multiple Checkboxes grouped together" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/check4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Checkboxes are very flexible components that can be placed anywhere you need to provide a user with choices. The checkbox items should work independently from each other, so selecting one checkbox shouldn’t change the status of another checkbox in the list`}</p>
    <p><strong parentName="p">{`Common places checkbox appear:`}</strong>{` Tables, Forms, settings pages, etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> To present multiple options that can each be selected. </li>
      <li> To offer help or error handling for simple settings. </li>
      <li> For changes that require confirmation after selection. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> When only one of multiple options can be selected. </li>
      <li> You want to group multiple options into a single basic choice. </li>
      <li> For single on/off settings with an instant response. </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`A checkbox is used to select a single item or multiple items, typically to choose elements to perform an action or to reflect a binary setting. Keyboard users will be able to interact with the checkbox component by pressing Space to select the input. We have added any attributes necessary to make it properly announced to screen readers.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With Padding" caption="Provide minimum 4 px of padding around the icon" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/check5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Without Padding" caption="Allow icon to extend to edge of container" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/check6.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <h3><strong parentName="h3">{`Margin and Padding`}</strong></h3>
    <p>{`Icons inside the container should have adequate padding around it to support visual hierarchy and balance. We recommend providing even padding along the top, bottom, right, and left of the icon.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/check7.svg",
      "alt": "checkbox guideline"
    }}></img>
    <p>{`Always maintain a minimum 16 pixel margin between buttons and other buttons or components so that they do not become lost.`}</p>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When writing checkbox labels:`}</p>
    <ul>
      <li parentName="ul">{`Checkbox labels should be clear, accurate and predictable. It should be possible for the user to understand what they are selecting`}</li>
      <li parentName="ul">{`Checkboxes should always have a Label associated to give users context of what the Checkbox represents`}</li>
      <li parentName="ul">{`Avoid ending in punctuation if it’s a single sentence, word, or a fragment`}</li>
      <li parentName="ul">{`Do not use commas or semicolons at the end of each line`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With spesific label item" caption="Use direct and concise button labels" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/check8.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : With long label item" caption="Don’t overwhelm users with too much information at once" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/check9.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      